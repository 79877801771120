import clsx from 'clsx';
import CopyToClipboard from 'components/FormComponents/FieldActions/CopyToClipboard';
import FieldArray from 'components/FormComponents/FieldArray';
import FormField from 'components/FormComponents/FormField';
import PhoneInput from 'components/PhoneInput';
import Select from 'components/Select';
import type { IProps as SelectProps } from 'components/Select/types';
import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { checkIfHasExceedFieldsLimit } from 'utils/shared';
import { isObject, isString } from 'utils/type-guards';

import { DynamicArrayField } from '../DynamicArrayField';
import styles from './styles.module.css';
import type { IProps } from './types';

type SelectFieldProps = Omit<SelectProps, 'ref'> & { name: string };
const SelectField = ({ name, disabled, ...restProps }: SelectFieldProps) => {
	const {
		field: { value, onChange, ...restField },
	} = useController({ name });

	return (
		<Select {...restProps} {...restField} disabled={disabled} value={value} setValue={(option) => onChange(isString(option) ? option : option)} />
	);
};

const PhoneArrayField: React.FC<IProps> = ({
	phoneAccessorPath,
	phoneTypeAccessorPath,
	name,
	disabled = false,
	limit,
	afterCopyText,
	beforeCopyText,
	badgeWidth,
	loadOptions,
	className,
}) => {
	const [options, setOptions] = useState([]);

	useEffect(() => {
		const data = loadOptions?.() ?? [];

		setOptions(data);
	}, [loadOptions]);

	return (
		<FieldArray name={name}>
			{({ fields, append, remove }) => {
				const fieldsCount = fields.length;

				const hasExceedFieldsLimit = checkIfHasExceedFieldsLimit(fieldsCount, limit);
				const disableAppendButton = hasExceedFieldsLimit;
				const disableRemoveButton = fieldsCount === 1;

				const handleFieldAppend = () => {
					if (hasExceedFieldsLimit) return;

					const newField = {
						[phoneAccessorPath]: '',
						[phoneTypeAccessorPath]: options[0],
					};

					append(newField);
				};

				const handleFieldRemove = () => {
					if (fieldsCount === 1) return;

					remove(fieldsCount - 1);
				};

				return (
					<DynamicArrayField
						key={'1'}
						name={name}
						fields={fields}
						noControls={disabled}
						onAppendField={handleFieldAppend}
						onRemoveField={handleFieldRemove}
						disableAppend={disableAppendButton}
						disableRemove={disableRemoveButton}
						renderComponent={({ path, field, index }) => {
							const editableComponent = ({ onChange, value, ...inputProps }) => (
								<div key={field.id + index} className={clsx(styles.phoneField)}>
									<PhoneInput setValue={onChange} value={value} {...inputProps} placeholder="38 (---) --- -- --" />
									<SelectField disabled={disabled} name={`${path}.${phoneTypeAccessorPath}`} valuesList={options} />
								</div>
							);

							const readonlyComponent = ({ onChange, value, ...inputProps }) => {
								const stringifiedValue = String(value);
								const { length } = stringifiedValue.trim();
								const width = badgeWidth || `${length + 6}ch`;
								const fieldValue = field[phoneTypeAccessorPath];
								const displayValue = isObject(fieldValue) ? fieldValue?.label : fieldValue;

								return (
									<div key={field.id + index} className={clsx(styles.field)}>
										<span className={styles.typeBadge} style={{ '--phone-type-badge-width': width } as React.CSSProperties}>
											{displayValue}
										</span>
										<PhoneInput {...inputProps} setValue={onChange} value={value} className={styles.input} />
									</div>
								);
							};

							const inputComponent = disabled ? readonlyComponent : editableComponent;

							return (
								<FormField
									key={`${path}.${phoneAccessorPath}` + field.id + index}
									label="Номер телефону"
									hiddenLabel={index !== 0}
									name={`${path}.${phoneAccessorPath}`}
									disabled={disabled}
									component={inputComponent}
									fieldClassName={className}
									{...(disabled && {
										actionSlot: (
											<CopyToClipboard
												name={name}
												afterCopyText={afterCopyText || 'Скопійовано в буфер'}
												beforeCopyText={beforeCopyText || 'Скопіювати'}
												className={styles.actionSlot}
											/>
										),
									})}
								/>
							);
						}}
					/>
				);
			}}
		</FieldArray>
	);
};

export default PhoneArrayField;
