import { MessengerSchema } from 'models/common/messengers';
import { OptionSchema, ServerSideOptionSchema } from 'models/common/options';
import { PhoneSchema } from 'models/common/phones';
import { EmployeeIdSchema, EmployeeOrganizationIdSchema } from 'models/common/uuid';
import { z } from 'zod';

export const BaseEmployee = z.object({
	id: EmployeeIdSchema,
	name: z.string().nullable(),
	isActive: z.number().nullish(),
});

export const OrderCreatorEmployee = BaseEmployee.extend({
	'1c_uuid': z.string().nullable(),
});

export const EmployeeOrganizationSchema = z.object({
	id: EmployeeOrganizationIdSchema,
	title: z.string().min(1),
});

export const DepartmentOptionSchema = z.object({
	id: z.string().nullish(),
	title: z.string().nullish(),
});
export const PositionOptionSchema = z.object({
	id: z.string().nullish(),
	title: z.string().nullish(),
});

export const EmployeeDepartmentSchema = ServerSideOptionSchema;
export const EmployeePositionSchema = ServerSideOptionSchema;
export const EmployeeStockSchema = ServerSideOptionSchema;
export const EmployeeRoleSchema = ServerSideOptionSchema;

export const HeadEmployee = BaseEmployee.merge(OrderCreatorEmployee).extend({
	email: z.string().email(),
	department: ServerSideOptionSchema,
	position: ServerSideOptionSchema,
	avatar: z.any().nullable(),
});

export const CreateEmployeeSchema = BaseEmployee.omit({ id: true }).extend({
	email: z.string().email(),
	oneCUuid: z.string(),
	headId: z.string().min(1),
	departmentId: z.string().min(1),
	positionId: z.string().min(1),
	stockId: z.string().min(1),
	description: z.string(),
	phones: z.array(PhoneSchema.omit({ priority: true })).min(1),
	messengers: z.array(MessengerSchema),
	role: z.string().min(1),
	avatar: z.any().nullable(),
});

export const UpdateEmployeeSchema = CreateEmployeeSchema.extend({
	id: z.string(),
});

export const EmployeePreviewSchema = BaseEmployee.extend({
	position: EmployeePositionSchema.nullable(),
	phones: z.array(PhoneSchema),
	role: z.string(),
	avatar: z.any().nullish(),
	email: z.string().email().or(z.literal('')).nullish(),
});

export const EmployeeSchema = BaseEmployee.merge(OrderCreatorEmployee)
	.merge(
		CreateEmployeeSchema.omit({
			departmentId: true,
			positionId: true,
			stockId: true,
		}),
	)
	.extend({
		department: ServerSideOptionSchema,
		position: ServerSideOptionSchema,
		stock: ServerSideOptionSchema,
		roles: EmployeeRoleSchema,
		head: HeadEmployee,
	});

export const CreateEmployeeColloquialDataSchema = z.object({
	departments: DepartmentOptionSchema.array(),
	positions: PositionOptionSchema.array(),
	users: OrderCreatorEmployee.merge(BaseEmployee).array(),
});

export const TransformedCreateEmployeeColloquialDataSchema = z.object({
	departments: OptionSchema.array(),
	positions: OptionSchema.array(),
	users: OptionSchema.array(),
});
export const EmployeesFiltersDataSchema = CreateEmployeeColloquialDataSchema.pick({ positions: true });
export const TransformedEmployeesFiltersDataSchema = TransformedCreateEmployeeColloquialDataSchema.pick({ positions: true });

export type CreateEmployee = z.infer<typeof CreateEmployeeSchema>;
export type UpdateEmployee = z.infer<typeof UpdateEmployeeSchema>;
export type EmployeePreview = z.infer<typeof EmployeePreviewSchema>;
export type Employee = z.infer<typeof EmployeeSchema>;
export type EmployeeOrganization = z.infer<typeof EmployeeOrganizationSchema>;
export type CreateEmployeeColloquialData = z.infer<typeof CreateEmployeeColloquialDataSchema>;
export type TransformedCreateEmployeeColloquialData = z.infer<typeof TransformedCreateEmployeeColloquialDataSchema>;
export type TransformedEmployeesFiltersData = z.infer<typeof TransformedEmployeesFiltersDataSchema>;
export type EmployeesFiltersData = z.infer<typeof EmployeesFiltersDataSchema>;
export type DepartmentOption = z.infer<typeof DepartmentOptionSchema>;
export type PositionOption = z.infer<typeof PositionOptionSchema>;
