import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { OriginalPaginatedRequestResult, PaginatedRequestResult, RequestResult } from 'models/api';
import { transformToPaginatedRequestResult } from 'models/api/transform';
import type { Option } from 'models/common/options';
import {
	CreateEmployee,
	CreateEmployeeColloquialData,
	CreateEmployeeColloquialDataSchema,
	Employee,
	EmployeeOptionSchema,
	EmployeePreview,
	EmployeePreviewSchema,
	EmployeesFiltersData,
	EmployeesFiltersDataSchema,
	OrganizationOptionSchema,
	ServerSideEmployeeOption,
	ServerSideOrganizationOption,
	SuspendEmployeeDTO,
	TransformedCreateEmployeeColloquialData,
	TransformedEmployeesFiltersData,
	UpdateEmployee,
} from 'models/employee';
import { ChangeEmployeePasswordDTO } from 'models/employee/password';
import { Stock, StockOptionSchema } from 'models/stock';
import { usersQueryKeys } from 'services/queryKeys';
import { transformInToFormDataObject } from 'utils/api';
import { throwAnException } from 'utils/api/errors';
import { logger } from 'utils/logger';

import apiClient, { baseApiUrl } from '../auth/apiClient';

export const usersSliceApi = createApi({
	reducerPath: 'users',
	baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
	tagTypes: [usersQueryKeys.users()],
	endpoints: (builder) => ({
		getEmployees: builder.query<PaginatedRequestResult<EmployeePreview[]>, string>({
			queryFn: async (queryParams) => {
				try {
					const response = await apiClient.get<OriginalPaginatedRequestResult<EmployeePreview[]>>('/users', {
						params: new URLSearchParams(queryParams),
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const result = transformToPaginatedRequestResult<EmployeePreview[]>(response.data);
					const validation = EmployeePreviewSchema.array().safeParse(result.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: {
								data: [],
								page: 1,
								pagesCount: 1,
							},
						};
					}

					return {
						data: {
							data: validation.data,
							page: result.page,
							pagesCount: result.pagesCount,
						},
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			providesTags: (result) =>
				result?.data ? [usersQueryKeys.users(), usersQueryKeys.usersOnly(), ...result.data.map(({ id }) => usersQueryKeys.user(id))] : [],
		}),
		getCreateEmployeeData: builder.query<TransformedCreateEmployeeColloquialData, void>({
			queryFn: async () => {
				try {
					const response = await apiClient.get<CreateEmployeeColloquialData>('/users/create');

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = CreateEmployeeColloquialDataSchema.safeParse(response.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: {
								departments: [],
								positions: [],
								users: [],
							},
						};
					}
					const { departments, positions, users } = validation.data;

					return {
						data: {
							departments: departments.map((item) => ({ label: item.title, value: item.id })),
							positions: positions.map((item) => ({ label: item.title, value: item.id })),
							users: users.map((item) => ({ label: item.name, value: item['1c_uuid'] ?? '' })),
						},
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		getEmployeesFilters: builder.query<TransformedEmployeesFiltersData, void>({
			queryFn: async (queryParams) => {
				try {
					const response = await apiClient.get<EmployeesFiltersData>('/users/filters', {
						params: queryParams,
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = EmployeesFiltersDataSchema.safeParse(response.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: {
								positions: [],
							},
						};
					}
					const { positions } = validation.data;

					return {
						data: {
							positions: positions.map((item) => ({ label: item.title, value: item.id })),
						},
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		getEmployeeById: builder.query<Employee, string>({
			queryFn: async (id) => {
				try {
					const response = await apiClient.get<RequestResult<Employee>>(`/users/${id}`);

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throwAnException(error);
				}
			},
			providesTags: (_, __, id) => [usersQueryKeys.user(id)],
		}),
		createEmployee: builder.mutation<Employee, CreateEmployee>({
			queryFn: async (data) => {
				try {
					const formData = transformInToFormDataObject(data);

					const response = await apiClient.post('/users', formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					});

					if (response.status !== 201) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throwAnException(error);
				}
			},
			invalidatesTags: [usersQueryKeys.usersOnly()],
		}),
		updateEmployee: builder.mutation<Employee, UpdateEmployee>({
			queryFn: async (employee) => {
				try {
					const response = await apiClient.patch<Employee>(`/users/${employee.id}`, employee);

					return {
						data: response.data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			invalidatesTags: [usersQueryKeys.usersOnly()],
			async onQueryStarted(employee, { dispatch, queryFulfilled }) {
				const optimisticOrderUpdate = dispatch(
					usersSliceApi.util.updateQueryData('getEmployeeById', String(employee.id), (draft) => {
						Object.assign(draft ?? {}, employee);
					}),
				);
				try {
					await queryFulfilled;
				} catch {
					optimisticOrderUpdate.undo();
				}
			},
		}),
		getWarehouses: builder.query<Option[], void>({
			queryFn: async () => {
				try {
					const response = await apiClient.get<Stock[]>('/stocks');

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					const validation = StockOptionSchema.array().safeParse(response.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return { data: [] };
					}

					return {
						data: validation.data as Option[],
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		getEmployeesOptionList: builder.query<Option[], void>({
			queryFn: async () => {
				try {
					const response = await apiClient.get<RequestResult<ServerSideEmployeeOption[]>>('/users/all');

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = EmployeeOptionSchema.array().safeParse(response.data.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: [],
						};
					}

					return {
						data: validation.data as Option[],
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		getOrganizationsOptionList: builder.query<Option[], void>({
			queryFn: async () => {
				try {
					const response = await apiClient.get<RequestResult<ServerSideOrganizationOption[]>>('/organizations');

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = OrganizationOptionSchema.array().safeParse(response.data.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: [],
						};
					}

					return {
						data: validation.data as Option[],
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		suspendEmployee: builder.mutation<Employee, SuspendEmployeeDTO>({
			queryFn: async (dto) => {
				try {
					const response = await apiClient.put<Employee>(`/users/${dto.id}/set-active`, { active: dto.active });

					return {
						data: response.data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			async onQueryStarted(dto, { dispatch, queryFulfilled }) {
				const optimisticOrderUpdate = dispatch(
					usersSliceApi.util.updateQueryData('getEmployeeById', String(dto.id), (draft) => {
						Object.assign(draft ?? {}, { isActive: Number(dto.active) });
					}),
				);
				try {
					await queryFulfilled;
				} catch {
					optimisticOrderUpdate.undo();
				}
			},
		}),
		changeEmployeePassword: builder.mutation<Employee, ChangeEmployeePasswordDTO>({
			queryFn: async (dto) => {
				try {
					const response = await apiClient.post<Employee>('/users/set-password', dto);

					return {
						data: response.data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			invalidatesTags: [usersQueryKeys.usersOnly()],
		}),
	}),
});

export const {
	useGetEmployeeByIdQuery,
	useGetEmployeesQuery,
	useUpdateEmployeeMutation,
	useGetWarehousesQuery,
	useCreateEmployeeMutation,
	useGetEmployeesOptionListQuery,
	useGetOrganizationsOptionListQuery,
	useGetCreateEmployeeDataQuery,
	useSuspendEmployeeMutation,
	useChangeEmployeePasswordMutation,
	useGetEmployeesFiltersQuery,
} = usersSliceApi;
