import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import { usePriceTypeColumns } from 'columns/priceType';
import TableCheckbox from 'components/Table/TableComponents/CheckboxColumn/TableCheckbox';
import { ActionCellContent } from 'components/Table/TableComponents/ContentOrientedCells';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { breakPoints } from 'const';
import { useOrderCart } from 'hooks/useOrderCart';
import type { CatalogueProduct, CatalogueProductWithAmount } from 'models/product/catalogue-product';
import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';
import { getPriceByTypePriceId } from 'store/reducers/orderViewer/utils';
import { formatNumberWithSpaces, numberify, toPrecise } from 'utils/shared';

import ProductAvailabilityController from '../components/ProductAvailabilityController';
import { adjustableColumnsIcons } from './icons';
import styles from './styles.module.css';

const columnHelper = createColumnHelper<CatalogueProduct>();

export interface UseCatalogueProductsColumnsProps {
	onProductPick?: (product: CatalogueProductWithAmount, e?: React.MouseEvent<HTMLElement>) => void;
	onModalOpen?: (product: CatalogueProductWithAmount, e?: React.MouseEvent<HTMLElement>) => void;
	baseCellSize?: number;
}

export const useCatalogueProductsColumns = ({ onProductPick, onModalOpen, baseCellSize = 100 }: UseCatalogueProductsColumnsProps) => {
	const isMobile = useMediaQuery({ maxWidth: breakPoints.MOBILE - 1 });
	const isTablet = useMediaQuery({ query: `(min-width: ${breakPoints.MOBILE}px) and (max-width: ${breakPoints.DESKTOP - 1}px)` });
	const isDesktop = useMediaQuery({ query: `(min-width: ${breakPoints.DESKTOP}px)` });
	const [searchParams] = useSearchParams();

	const suborderIndex = Number(searchParams.get('from') ?? 0);

	const priceTypeColumns = usePriceTypeColumns({ isDesktop, isTablet, suborderIndex: suborderIndex });

	const columns = useMemo(
		() => [
			columnHelper.accessor('select', {
				id: 'select',
				header: ({ table }) => {
					const cart = useOrderCart();

					const handleSelect = () => {
						const isAllSelected = table.getIsAllRowsSelected();

						if (isAllSelected) {
							cart.clearMaybeSelectedAll();
						} else {
							cart.maybeSelectMany(table.getRowModel().rows.map((row) => row.original));
						}
						table.toggleAllRowsSelected();
					};

					return (
						<HeaderCell>
							<TableCheckbox
								{...{
									checked: table.getIsAllRowsSelected(),
									indeterminate: table.getIsSomeRowsSelected(),
									onChange: handleSelect,
								}}
							/>
						</HeaderCell>
					);
				},
				cell: ({ row }) => {
					const cart = useOrderCart();
					const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
						const product = row.original;

						if (e.currentTarget.checked) {
							cart.maybeSelectMany([...cart.products, { ...product, amount: product?.unit?.isInt ? '1' : '1.00' }]);
						} else {
							cart.maybeSelectMany(cart.products.filter((pr) => pr.id !== product.id));
						}

						row.toggleSelected();
					};

					return (
						<RegularCell>
							<TableCheckbox
								{...{
									checked: row.getIsSelected(),
									disabled: !row.getCanSelect(),
									indeterminate: row.getIsSomeSelected(),
									onChange: handleSelectionChange,
								}}
							/>
						</RegularCell>
					);
				},
				enableSorting: false,
				size: 60,
				meta: {
					headerCellContentOffset: '19px',
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Вибір',
				},
			}),
			columnHelper.accessor((row) => row.code, {
				id: 'code',
				header: () => <HeaderCell>Код товару</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				enableSorting: false,
				enableHiding: true,
				size: baseCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Код товару',
					icon: <adjustableColumnsIcons.FinalCost />,
				},
			}),
			...(isTablet
				? [
						columnHelper.accessor((row) => row.brand.title, {
							id: 'brand',
							header: () => <HeaderCell>Товар</HeaderCell>,
							cell: (cell) => (
								<RegularCell onClick={() => onProductPick?.(cell.row.original as CatalogueProductWithAmount)}>
									<span className="tablet-cell text-left">
										<span>{cell.getValue()}</span>
										<span>{cell.row.original.title}</span>
									</span>
								</RegularCell>
							),
							size: baseCellSize * 2.1,
							enableSorting: false,
							enableHiding: true,
							meta: {
								getCanRenderOnMobile: () => false,
								getVisibilityToggleTitle: () => 'Товар',
								icon: <adjustableColumnsIcons.Product />,
							},
						}),
				  ]
				: [
						columnHelper.accessor((row) => row.title, {
							id: 'title',
							header: () => <HeaderCell {...(isDesktop && { justify: 'start' })}>Товар</HeaderCell>,
							cell: (cell) => {
								const handleClick = () => {
									if (isMobile) return;
									onModalOpen?.(cell.row.original as CatalogueProductWithAmount);
								};

								return (
									<RegularCell className="justify-start text-left" onClick={handleClick}>
										{cell.getValue()}
									</RegularCell>
								);
							},
							size: 380,
							enableSorting: false,
							enableHiding: true,
							meta: {
								getCanRenderOnMobile: () => false,
								getVisibilityToggleTitle: () => 'Товар',
								icon: <adjustableColumnsIcons.Product />,
							},
						}),
						columnHelper.accessor((row) => row.brand.title, {
							id: 'brand',
							header: () => <HeaderCell {...(isDesktop && { justify: 'start' })}>Бренд</HeaderCell>,
							cell: (cell) => (
								<RegularCell {...(isDesktop && { justify: 'start' })} className={clsx(styles.highlightGray, styles.mediumBold)}>
									{cell.getValue()}
								</RegularCell>
							),
							maxSize: 200,
							enableSorting: false,
							enableHiding: true,
							meta: {
								getCanRenderOnMobile: () => false,
								getVisibilityToggleTitle: () => 'Бренд',
								icon: <adjustableColumnsIcons.Brand />,
							},
						}),
				  ]),
			columnHelper.accessor(() => null, {
				id: 'price',
				header: () => <HeaderCell className={clsx(styles.highlightPrimary600, styles.semibold)}>Ціна DIY</HeaderCell>,
				cell: (cell) => {
					const price = numberify(getPriceByTypePriceId(cell.row.original, 'ef741b50-b356-11e3-af42-8b1ea7c04b02')) || '-';
					const displayPrice = formatNumberWithSpaces(toPrecise(price));
					const handleClick = (event) => {
						event.stopPropagation();
						alert('to price change page');
					};
					return (
						<RegularCell>
							{isMobile ? (
								<ActionCellContent onChangeClick={handleClick} className={clsx(styles.highlightPrimary600, styles.mediumBold)}>
									<span>{displayPrice}</span>
								</ActionCellContent>
							) : (
								displayPrice
							)}
						</RegularCell>
					);
				},
				enableSorting: true,
				enableHiding: true,
				size: baseCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Ціна DIY',
					icon: <adjustableColumnsIcons.Price />,
					sortQueryKey: 'price',
				},
			}),
			/**
			 * ======
			 * here we inject type price columns
			 */
			...priceTypeColumns,
			/**
			 * ====
			 */
			columnHelper.accessor((row) => row.reserves, {
				id: 'reserves',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Резерв</HeaderCell>,
				cell: (cell) => (
					<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })} className="highlighter-200">
						<ProductAvailabilityController entity={cell.row.original} suborderIndex={suborderIndex} watchKey="reserves" />
					</RegularCell>
				),
				enableHiding: true,
				enableSorting: true,
				size: baseCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Резерв',
					getMobileHeaderCell: () => 'Резерв',
					icon: <adjustableColumnsIcons.InReserve />,
					sortQueryKey: 'reserves',
				},
			}),
			columnHelper.accessor((row) => row.points ?? '—', {
				id: 'points',
				header: () => <HeaderCell>Бали</HeaderCell>,
				cell: (cell) => {
					return <RegularCell className="justify-center text-center">{cell.getValue()}</RegularCell>;
				},
				enableHiding: true,
				enableSorting: false,
				size: baseCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Бали',
					getMobileHeaderCell: () => 'Бали',
					icon: <adjustableColumnsIcons.Points />,
				},
			}),
			columnHelper.accessor((row) => row.leftovers, {
				id: 'leftovers',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Залишок</HeaderCell>,
				cell: (cell) => (
					<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
						<ProductAvailabilityController entity={cell.row.original} suborderIndex={suborderIndex} watchKey="leftovers" />
					</RegularCell>
				),
				enableHiding: true,
				enableSorting: true,
				size: baseCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Залишок',
					getMobileHeaderCell: () => 'Залишок',
					icon: <adjustableColumnsIcons.Remaining />,
					sortQueryKey: 'leftovers',
				},
			}),

			columnHelper.accessor((row) => row.unit.title, {
				id: 'unit',
				header: () => <HeaderCell>Од-ці</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				enableSorting: false,
				enableHiding: true,
				size: baseCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Од-ці',
					icon: <adjustableColumnsIcons.MeasureUnit />,
				},
			}),
			columnHelper.accessor((row) => row.freeLeftovers, {
				id: 'freeLeftovers',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Вільний залишок</HeaderCell>,
				cell: (cell) => (
					<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
						<ProductAvailabilityController entity={cell.row.original} suborderIndex={suborderIndex} watchKey="freeLeftovers" />
					</RegularCell>
				),
				enableHiding: true,
				enableSorting: false,
				size: baseCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Вільний залишок',
					getMobileHeaderCell: () => 'Вільний залишок',
					icon: <adjustableColumnsIcons.AvailableBalance />,
				},
			}),
		],
		[isMobile, isTablet, isDesktop, priceTypeColumns, suborderIndex, baseCellSize],
	);

	return columns;
};
