import { ClientEditProhibitedFieldNames } from 'models/IClient';
import FacebookIcon from 'static/images/facebook-02.svg';
import InstagramIcon from 'static/images/instagram.svg';
import TelegramIcon from 'static/images/telegram.svg';
import ViberIcon from 'static/images/viber.svg';
import { prepareUrl } from 'utils/shared';

export const UA_PHONE_CODE = '38';

export const ROUTES_URLS = {
	HOME: '/',
	SIGN_IN: 'sign-in',
	FORGOT_PASSWORD: 'forgot-password',
	RESET_PASSWORD: 'password/reset/:token',
	// SIGN_UP: '/sign-up',
	ORDER_SELECT_CLIENT: 'order/select-client',
	ORDER_SELECT_PRODUCT: 'order/:id/select-product',
	ORDER_SERVICES: 'services',
	CLIENT_PAGE: 'client/:id',
	ORDER_NEW: 'order/new',
	NEW_ORDER: 'order/create/new-order',

	EMPLOYEE_EDIT: 'employees/:id/edit',
	AUTH_VIA_INVITATION_LINK: 'sign-in/link/:token',
	COMMERCIAL_PROPOSAL: 'commercial-proposal',
	CREATE_ORDER_SELECT_PRODUCT: 'order/select-product',

	CLIENTS: 'clients',
	CLIENT_PREVIEW: 'client/:id',
	CLIENT_CREATE: 'client/create',
	CLIENT_ORDERS: 'clients/:id/orders',

	DOCUMENTS: 'documents/:id',
	PRICES: 'prices',
	PRICES_CREATE: 'prices/new',
	PRICES_SAVE: 'prices/new/save',
	PAINT_TONING: 'paint-toning',

	ORDERS: 'orders',

	ORDER_ROOT_SEGMENT: 'order',

	ORDER_PREVIEW_ROOT: 'preview',
	ORDER_PREVIEW_SEGMENT: ':id',
	ORDER_PREVIEW_PRODUCTS_SEGMENT: 'products',
	ORDER_PREVIEW_SERVICES_SEGMENT: 'services',
	ORDER_PREVIEW_ADD_PRODUCTS_SEGMENT: 'add-products',
	ORDER_PREVIEW_SPLIT_ORDER_SEGMENT: 'split',
	ORDER_PREVIEW_CLIENT_CARD_SEGMENT: 'client-card',

	ORDER_NEW_ROOT: 'new',
	ORDER_NEW_PRODUCTS_SEGMENT: 'products',
	ORDER_NEW_SERVICES_SEGMENT: 'services',
	ORDER_NEW_SET_CLIENT_SEGMENT: 'set-client',
	ORDER_NEW_ADD_PRODUCTS_SEGMENT: 'add-products',
	ORDER_NEW_SPLIT_ORDER_SEGMENT: 'split',
	ORDER_NEW_CLIENT_CARD_SEGMENT: 'client-card',

	ORDER_SPLIT: 'order/:id/split',
	EMPLOYEES: 'employees',
	EMPLOYEE: 'employees/:id',
	EMPLOYEE_CREATE: 'employees/new',
};

export const orderUrlUtil = {
	getPreviewRoot() {
		return ROUTES_URLS.ORDER_PREVIEW_ROOT;
	},
	getNewOrderRoot() {
		return ROUTES_URLS.ORDER_NEW_ROOT;
	},
	getFullPreviewUrl() {
		return prepareUrl(this.getPreviewRoot() + '/' + ROUTES_URLS.ORDER_ROOT_SEGMENT + '/' + ROUTES_URLS.ORDER_PREVIEW_SEGMENT);
	},
	getFullPreviewProductsUrl() {
		return this.getFullPreviewUrl() + '/' + ROUTES_URLS.ORDER_PREVIEW_PRODUCTS_SEGMENT;
	},
	getFullPreviewServicesUrl() {
		return this.getFullPreviewUrl() + '/' + ROUTES_URLS.ORDER_PREVIEW_SERVICES_SEGMENT;
	},
	getFullPreviewSetProductsUrl() {
		return this.getFullPreviewUrl() + '/' + ROUTES_URLS.ORDER_PREVIEW_ADD_PRODUCTS_SEGMENT;
	},
	getFullPreviewSplitOrderUrl() {
		return this.getFullPreviewUrl() + '/' + ROUTES_URLS.ORDER_PREVIEW_SPLIT_ORDER_SEGMENT;
	},
	getFullPreviewClientCardUrl() {
		return (
			this.getPreviewRoot() +
			'/' +
			ROUTES_URLS.ORDER_ROOT_SEGMENT +
			'/' +
			ROUTES_URLS.ORDER_PREVIEW_SEGMENT +
			'/' +
			ROUTES_URLS.ORDER_PREVIEW_CLIENT_CARD_SEGMENT +
			'/:clientId'
		);
	},
	getFullNewOrderUrl() {
		return this.getNewOrderRoot() + '/' + ROUTES_URLS.ORDER_ROOT_SEGMENT;
	},
	getFullNewOrderProductsUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_PRODUCTS_SEGMENT;
	},
	getFullNewOrderServicesUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_SERVICES_SEGMENT;
	},
	getFullNewOrderSetProductsUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_ADD_PRODUCTS_SEGMENT;
	},
	getFullNewOrderSetClientUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_SET_CLIENT_SEGMENT;
	},
	getFullNewOrderSplitOrderUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_SPLIT_ORDER_SEGMENT;
	},
	getFullNewOrderClientCardUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_CLIENT_CARD_SEGMENT + '/:clientId';
	},
};

export const breakPoints = {
	MOBILE: 768,
	TABLET: 1024,
	LAPTOP_SM: 1200,
	LAPTOP_MD: 1366,
	DESKTOP: 1920,
};

export const statusesList = ['Усі', 'Відгружено', 'В резерві', 'Прорахунок', 'Оплачено', 'На узгодженні', 'Кредит'];

export const positionsList = ['Усі', 'Прораб', 'Будівельник', 'Будівельна Організація'];

export const MAX_CHARS = 425;

export const ACCEPTED_FILE_TYPES = {
	'application/pdf': [],
	'image/png': [],
	'image/jpg': [],
	'image/jpeg': [],
	'image/gif': [],
};
export const MAX_DIMENSION = {
	width: 800,
	height: 400,
};
export const MAX_FILE_SIZE_IN_BYTES = 10 * 1024 * 1024; // equals 10 MB

export const ORDER_RESERVATION_STATUS = '2';
export const ORDER_AGREEMENT_STATUS = '1';
export const ORDER_NEWLY_CREATED_STATUS = ORDER_AGREEMENT_STATUS;

export const CLIENTS_FILTER_QUERY_KEYS = {
	client: 'client[]',
	segment: 'segment[]',
	manager: 'manager[]',
} as const;

export const ORDERS_FILTER_QUERY_KEYS = {
	sum: 'sum',
	payment: 'paymentStatus[]',
	shipment: 'status[]',
	client: 'client[]',
	// manager: 'manager',
	number: 'number',
	stock: 'stock[]',
	date: 'date',
	contract: 'contract[]',
	responsible: 'responsible[]',
} as const;

export const HUMAN_ORDERS_FILTER_QUERY_KEYS: Record<keyof typeof ORDERS_FILTER_QUERY_KEYS, (type?: 'long' | 'short') => string> = {
	sum: () => 'Сума',
	payment: () => 'Оплата',
	shipment: () => 'Відвантаження',
	client: () => 'Клієнт',
	// manager: () => 'Менеджер',
	number: () => 'Номер',
	stock: () => 'Склад',
	date: () => 'Дата',
	contract: () => 'Контракт',
	responsible: () => 'Відп. особа',
};

export const HUMAN_CLIENTS_FILTER_QUERY_KEYS: Record<keyof typeof CLIENTS_FILTER_QUERY_KEYS, (type?: 'long' | 'short') => string> = {
	client: () => 'Контрагент',
	segment: () => 'Сегмент',
	manager: () => 'Менеджер',
	// delay: () => 'Відстрочка',
	// lastSell: () => 'Ост. продаж',
	// limit: () => 'Ліміт',
};

export const ORDER_IS_SAVED_STATUS = '1';
export const ORDER_IS_RESERVED_STATUS = '2';

export const PHONE_TYPES_OPTION_LIST = [
	{ label: 'Робочий', value: 'phone_number_work' },
	{ label: 'Особистий', value: 'phone_number_personal_main' },
	{ label: 'Додатковий', value: 'phone_number_personal_additional' },
];
export const EMPLOYEE_PHONE_TYPE_DICT = {
	phone_number_work: { label: 'Робочий', value: 'phone_number_work' },
	phone_number_personal_main: { label: 'Особистий', value: 'phone_number_personal_main' },
	phone_number_personal_additional: { label: 'Додатковий', value: 'phone_number_personal_additional' },
};
export const PHONE_PRIORITY_LIST = ['phone_number', 'phone_number_additional_1', 'phone_number_additional_2'];

export const ORGANIZATIONS = [
	{
		label: 'Юніт Бі',
		value: '999c7041-f145-11e2-b6d1-c189088e7082',
	},
];

export const MESSENGERS_LIST = [
	{ icon: FacebookIcon, messenger: 'facebook', nickNameExample: '@username' },
	{ icon: InstagramIcon, messenger: 'instagram', nickNameExample: '@username' },
	{ icon: TelegramIcon, messenger: 'telegram', nickNameExample: '@username' },
	{ icon: ViberIcon, messenger: 'viber', nickNameExample: '@username' },
];

export const EMPLOYEE_PROFILE_DYNAMIC_FIELDS_LIMIT = {
	phone: 3,
	messengers: MESSENGERS_LIST.length,
};

export const CONTRACT_TYPES_OPTION_LIST = [
	{ label: 'Загальний Ф2', value: 'Загальний Ф2' },
	{ label: 'Загальний БД', value: 'Загальний БД' },
	{ label: 'Загальний ЮБ', value: 'Загальний ЮБ' },
];

export const PRICES_FILTERS = [
	{
		label: 'Клієнт',
		value: 'client',
	},
	{
		label: 'Назва',
		value: 'name',
	},
	{
		label: 'Дата',
		value: 'date',
	},
];

export const PRICES_TYPE_PRICE = [
	{
		label: 'Оптова',
		value: 'wholesale',
	},
	{
		label: 'Роздрібна',
		value: 'retail',
	},
	{
		label: 'Мінімальна',
		value: 'min',
	},
	{
		label: 'Мінімальна +2%',
		value: 'min+2%',
	},
];

export const GROUPS = [
	{
		label: 'Дорівнює',
		value: 'equals',
	},
	{
		label: 'Не дорівнює',
		value: 'noEquals',
	},
	{
		label: 'В списку',
		value: 'inTheList',
	},
	{
		label: 'Не у списку',
		value: 'notOnTheList',
	},
	{
		label: 'В групі зі списку',
		value: 'inGroupFromList',
	},
	{
		label: 'Не в групі зі списку',
		value: 'notInTheGroupFromList',
	},
	{
		label: 'В групі',
		value: 'inGroup',
	},
	{
		label: 'Не в групі',
		value: 'notInTheGroup',
	},
];

export const CLIENT_PHONE_ASSOCIATED_OPTION_LIST = [{ label: 'Мобільний', value: 'mobile' } as const, { label: 'Міський', value: 'city' } as const];
export const CLIENT_PHONE_TYPE_TO_HUMAN = {
	city: 'Міський',
	mobile: 'Мобільний',
};

export const SEGMENTS_WITH_SPECIALIZATION = ['Будівельна організація спеціалізована', 'Виконроб'];

export const EDRPOU_CODE_LENGTH = 8;

// ==== VIRTUAL LIST CONFIG
export const FILTER_ITEM_SIZE = 20;
export const GUTTER = 20;
export const MAX_LIST_HEIGHT = 485;

export const CLIENT_DISABLED_FIELDS_NAMES_LIST: ClientEditProhibitedFieldNames[] = ['contract', 'status'];
export const SUBORDERS_LIMIT = 5;

// === CLIENT SEARCH PARAMS CONFIG
export const CLIENT_PAGE_FILTER_KEYS = {
	waiting: 'waiting',
	overdue: 'overdue',
	prepaid: 'prepaid',
};

export const SERVER_ENTITY_ID = {
	ServiceDYIPriceType: 'ef741b50-b356-11e3-af42-8b1ea7c04b02',
	ProductDYIPriceType: 'ef741b50-b356-11e3-af42-8b1ea7c04b02',
	ProductMinimalPriceType: '82dd3937-2316-11ea-80d5-8107dcf40211',
	ProductEnterPriceType: '95d8c530-6303-11e3-8680-c52dd1806f02',
	ProductWholeSalePriceType: '2d8b7e50-6709-11e3-b37c-d8698ef02892',
	ServicesNotAllowedManuallyEditPrice: ['2cf3b1b3-5e6c-11e3-8adb-e3aa534060cd', '1ec61b3f-9146-11e6-80d5-3497f6001881'],
};
// === CLIENTS FAST FILTERS GROUP
export const CLIENTS_FAST_FILTERS = [
	{
		Будівельники: [
			'f0c51b63-738a-4682-8cc3-b67653c1c37b', // Виконроб
			'cdf35028-7979-4b05-a7db-b1ed4eda4779', // Майстер
			'785e3c80-cade-44f2-b74a-3554905a9d82', // Дизайнер,
			'0c409927-9b34-4c2e-8b7e-9e17924897c0', // Забудовник
		],
	},
	{
		'Будівельні організації': [
			'74176216-4b64-4a2b-beec-f6b0f4119a7e', // Будівельна організація
			'c74c6ee8-1242-4895-9925-a8c3867c01cf', // Будівельна організація спеціалізована
		],
	},
	{
		Підприємства: [
			'e12bee00-4295-4140-adf8-fcb4b093178f', // Підприємство
		],
	},
	{
		'Кінцевий споживач': [
			'49505163-c5f4-43bd-837e-51ab0741814e', // Кінцевий споживач
		],
	},
];

export const CLIENT_STATUS = {
	Green: 'green',
	White: 'white',
	Yellow: 'yellow',
	Red: 'red',
} as const;

export const CLIENT_INFO_BY_STATUS = {
	[CLIENT_STATUS.White]: {
		name: 'Білий контрагент',
		color: 'var(--base-light)',
	},
	[CLIENT_STATUS.Green]: {
		name: 'Зелений контрагент',
		color: 'var(--success-500)',
	},
	[CLIENT_STATUS.Yellow]: {
		name: 'Жовтий контрагент',
		color: 'var(--warning-200)',
	},
	[CLIENT_STATUS.Red]: {
		name: 'Червоний контрагент',
		color: 'var(--error-500)',
	},
};
export const CLIENT_STATE_NAMES_LIST = Object.entries(CLIENT_INFO_BY_STATUS).map(([statusKey, value]) => ({ label: value.name, value: statusKey }));

export const PRE_FILTER_CLIENT_ROLES = ['manager', 'head_manager'];

export const USER_ROLE_TO_TITLE = {
	manager: 'Менеджер',
	none: 'Не зазначено',
	head_manager: 'Керівник',
	admin: 'Адміністратор',
	back_manager: 'Бек менеджер',
};
